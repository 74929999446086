import React, { useCallback, useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "./Sidebar";
import RightSidebar from "./RightSidebar";
import {
  Backdrop,
  Box,
  Button,
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
import theme from "../../theme";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { BASE_URL, FILE_CATEGORY } from "../../Constants/Constants";
import { useDropzone } from "react-dropzone";
import { toast, ToastContainer } from "react-toastify";
import CustomLoader from "../../utils/CustomLoader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getToken } from "../../utils/utils";
import { Refresh } from "@mui/icons-material";
import {
  FETCH_ERROR,
  FILE_UPLOAD_ERROR_MESSAGE,
  FILE_UPLOAD_SUCCESS_MESSAGE,
  FILE_UPLOAD_REQUIRED_MESSAGE,
} from "../../utils/Validation";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((themes) => ({
  MainContainer: {
    display: "flex",
    width: "100%",
    background: "#F1F1F1",
  },
  DashboardContainer: {
    background: theme.palette.secondary.main,
    height: "93vh",
    width: "60%",
    padding: 20,
    overflow: "auto",
  },
  TopHeader: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  Breadcrumbs: {
    display: "flex",
    gap: 4,
  },
  BreadcrumbsText: {
    color: theme.palette.secondary.black,
    fontFamily: "Helvetica",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "30px",
  },
  BreadcrumbsIcon: {
    width: "24px",
    height: "24px",
  },
  button: {
    marginTop: "20px !important",
    width: "200px !important",
    textTransform: "capitalize !important",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  AnalysisContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "98%",
  },
  EditBtn: {
    background: theme.palette.secondary.main,
    border: "none !important",
  },
}));

const Analysis = {
  signing_the_contract: "Signing The Contract",
  validity: "Validity",
  extension: "Extension",
  renewal: "Renewal",
  annual_adjustment: "Annual Adjustment",
  subcontracting: "Subcontracting",
};
const C2Analysis = {
  subscription_of_the_contract: "Subscription Of The Contract",
  validity: "Validity",
  renewal: "Renewal",
  extension: "Extension",
  subcontracting: "Subcontracting",
  re_adjustability: "Re Adjustability",
  price: "Price",
  intellectual_property: "Intellectual Property",
  deadlines: "Deadlines",
};

const C3Analysis = {
  renewal: "renewal",
  extension: "extension",
  re_adjustability: "re_adjustability",
  subcontracting: "subcontracting",
  price: "price",
  intellectual_property: "intellectual_property",
  deadlines: "deadlines",
};

const Comments = {
  budget_available: "Budget Available",
  guarantee_of_seriousness_offer: "Guarantee of Seriousness Offer",
  guarantee_of_faithful_compliance: "Guarantee of Faithful Compliance",
  fines: "Fines",
  early_termination: "Early Termination",
  pay: "Pay",
};

const C2Comments = {
  estimated_contract_amount: "Estimated Contract Amount",
  guarantees: "Guarantee of Seriousness Offer",
  offer_validity: "Offer Validity",
  scope: "Scope",
  evaluation_criteria: "Evaluation Criteria",
  termination_of_contract: "Termination of Contract",
  confidentiality: "Confidentiality",
  payment_term: "Payment Term",
  budget_modification: "Budget Modification",
  fines: "Fines",
  presentation_of_warranty: "Presentation of Warranty",
  extension_of_the_contract_term: "Extension of the Contract Term",
  early_termination: "Early Termination",
  outsourcing: "Outsourcing",
  method_of_payment: "Method of Payment",
  information_security: "Information Security",
  ownership_of_information: "Ownership of Information",
};

const C3Comments = {
  guarantee_of_seriousness_offer: "guarantee_of_seriousness_offer",
  offer_validity: "offer_validity",
  scope: "scope",
  termination_of_contract: "termination_of_contract",
  confidentiality: "confidentiality",
  payment_term: "payment_term",
  fines: "fines",
  extension_of_the_contract_term: "extension_of_the_contract_term",
  early_termination: "early_termination",
  outsourcing: "outsourcing",
  method_of_payment: "method_of_payment",
  information_security: "information_security",
  ownership_of_information: "ownership_of_information",
};

const TechnicalComments = {
  applicable_standards: "Applicable Standards",
  required_resources: "Required Resources",
  trainings: "Trainings",
  average_monthly_consumption: "Average Monthly Consumption",
  emergency_service: "Emergency Service",
  corrective_maintenance: "Corrective Maintenance",
};

const C2TechnicalComments = {
  technical_requirements: "Technical Requirements",
  training_plan: "Training Plan",
  maintenance: "Maintenance",
  execution_deadlines: "Execution Deadlines",
  staff_required: "Staff Required",
};

const C3TechnicalComments = {
  technical_requirements: "technical_requirements",
  maintenance: "maintenance",
  execution_deadlines: "execution_deadlines",
  staff_required: "staff_required",
};

const MetaDataObj = {
  document_for_customer: "Document for Customer",
  document_of: "Document of",
  document_date: "Document Date",
  document_reference: "Document Reference",
};

const itServiceDocument = {
  document_for_customer: "Document for Customer",
  document_of: "Document Of",
  document_date: "Document Date",
  document_reference: "Document Reference",
  document_introduction: "Document Introduction",
  document_summary: "Document Summary",
};

const itServiceComments = {
  comments_subscription_of_the_contract: "Subscription of the contract",
  comments_validity: "Validity",
  comments_renewal: "Renewal",
  comments_extension: "Extension",
  comments_subcontracting: "Subcontracting",
  comments_re_adjustability: "Re-adjustability",
  comments_price: "Price",
  comments_intellectual_property: "Intellectual Property",
  comments_deadlines: "Deadlines",
};

const itServiceAdministartiveBasis = {
  administrative_bases_estimated_contract_amount: "Estimated contract amount",
  administrative_bases_guarantees: "Guarantees",
  administrative_bases_offer_validity: "Offer validity",
  administrative_bases_scope: "Scope",
  administrative_bases_evaluation_criteria: "Evaluation criteria",
  administrative_bases_termination_of_contract: "Termination of contract",
  administrative_bases_confidentiality: "Confidentiality",
  administrative_bases_payment_term: "Payment term",
  administrative_bases_budget_modification: "Budget modification",
  administrative_bases_fines: "Fines",
  administrative_bases_presentation_of_warranty: "Presentation of warranty",
  administrative_bases_extension_of_the_contract_term:
    "Extension of the contract term",
  administrative_bases_early_termination: "Early termination",
  administrative_bases_outsourcing: "Outsourcing",
  administrative_bases_method_of_payment: "Method of payment",
  administrative_bases_information_security: "Information security",
  administrative_bases_ownership_of_information: "Ownership of information",
};

const itServicesTechnical = {
  technical_bases_technical_requirements: "Technical requirements",
  technical_bases_training_plan: "Training plan",
  technical_bases_maintenance: "Maintenance",
  technical_bases_execution_deadlines: "Execution deadlines",
  technical_bases_staff_required: "Staff required",
};

const Dashboard = () => {
  const classes = useStyles();
  const [UploadFileID, setUploadFileID] = useState("");
  const [uploadFileName, setUploadFileName] = useState([]);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState(null);
  const [AnalysisData, setAnalysisData] = useState(null);
  const [commentsData, setCommentsData] = useState(null);
  const [technicalCommentsData, setTechnicalCommentsData] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const [summaryData, setSummaryData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAnalyze, setIsAnalyze] = useState(false);
  const [expanded, setExpanded] = useState({});
  const [isEdit, setIsEdit] = useState("");
  const [saveDocumentID, setSaveDocumentID] = useState("");
  const token = getToken();
  const location = useLocation();
  const matchData = location.state?.matchData;
  useEffect(() => {
    if (matchData === undefined) {
      return;
    }
    if (matchData && matchData.category === FILE_CATEGORY.it) {
      const {
        document_for_customer,
        document_id,
        document_of,
        document_date,
        document_reference,
        document_introduction,
        document_summary,
        comments_subscription_of_the_contract,
        comments_validity,
        comments_re_adjustability,
        comments_deadlines,
        comments_extension,
        comments_renewal,
        comments_subcontracting,
        comments_intellectual_property,
        comments_price,
        administrative_bases_offer_validity,
        administrative_bases_guarantees,
        administrative_bases_scope,
        administrative_bases_termination_of_contract,
        administrative_bases_confidentiality,
        administrative_bases_payment_term,
        administrative_bases_fines,
        administrative_bases_extension_of_the_contract_term,
        administrative_bases_early_termination,
        administrative_bases_outsourcing,
        administrative_bases_method_of_payment,
        administrative_bases_information_security,
        administrative_bases_ownership_of_information,
        administrative_bases_estimated_contract_amount,
        administrative_bases_budget_modification,
        administrative_bases_presentation_of_warranty,
        administrative_bases_evaluation_criteria,
        technical_bases_training_plan,
        technical_bases_technical_requirements,
        technical_bases_maintenance,
        technical_bases_execution_deadlines,
        technical_bases_staff_required,
      } = matchData;
      setSaveDocumentID(document_id);
      setCategory(FILE_CATEGORY.it);
      setMetaData({
        data: {
          document_for_customer,
          document_of,
          document_date,
          document_reference,
        },
      });

      setSubCategory({
        category: matchData.category,
        introduction: document_introduction,
      });

      setSummaryData({ data: document_summary });

      setAnalysisData({
        analysis: {
          subscription_of_the_contract: comments_subscription_of_the_contract,
          validity: comments_validity,
          re_adjustability: comments_re_adjustability,
          deadlines: comments_deadlines,
          extension: comments_extension,
          renewal: comments_renewal,
          subcontracting: comments_subcontracting,
          intellectual_property: comments_intellectual_property,
          price: comments_price,
        },
      });

      setCommentsData({
        offer_validity: administrative_bases_offer_validity,
        guarantees: administrative_bases_guarantees,
        scope: administrative_bases_scope,
        termination_of_contract: administrative_bases_termination_of_contract,
        confidentiality: administrative_bases_confidentiality,
        payment_term: administrative_bases_payment_term,
        fines: administrative_bases_fines,
        extension_of_the_contract_term:
          administrative_bases_extension_of_the_contract_term,
        early_termination: administrative_bases_early_termination,
        outsourcing: administrative_bases_outsourcing,
        method_of_payment: administrative_bases_method_of_payment,
        information_security: administrative_bases_information_security,
        ownership_of_information: administrative_bases_ownership_of_information,
        estimated_contract_amount:
          administrative_bases_estimated_contract_amount,
        budget_modification: administrative_bases_budget_modification,
        presentation_of_warranty: administrative_bases_presentation_of_warranty,
        evaluation_criteria: administrative_bases_evaluation_criteria,
      });

      setTechnicalCommentsData({
        data: {
          training_plan: technical_bases_training_plan,
          technical_requirements: technical_bases_technical_requirements,
          maintenance: technical_bases_maintenance,
          execution_deadlines: technical_bases_execution_deadlines,
          staff_required: technical_bases_staff_required,
        },
      });
    } else if (matchData && matchData.category === FILE_CATEGORY.distribution) {
      const {
        document_for_customer,
        document_id,
        document_of,
        document_date,
        document_reference,
        document_introduction,
        document_summary,
        comments_signing_the_contract,
        comments_validity,
        comments_extension,
        comments_renewal,
        comments_annual_adjustment,
        comments_subcontracting,
        administrative_basis_budget_available,
        administrative_basis_guarantee_of_seriousness_offer,
        administrative_basis_guarantee_of_faithful_compliance,
        administrative_basis_fines,
        administrative_basis_early_termination,
        administrative_basis_pay,
        technical_basis_applicable_standards,
        technical_basis_required_resources,
        technical_basis_trainings,
        technical_basis_average_monthly_consumption,
        technical_basis_emergency_service,
        technical_basis_corrective_maintenance,
        technical_basis_additional_obligations,
      } = matchData;
      const regex = /([a-f0-9]{8}-([a-f0-9]{4}-){3}[a-f0-9]{12})/i;
      const match = document_reference?.match(regex);
      setUploadFileID(match?.[0] || "");
      setSaveDocumentID(document_id);
      setCategory(FILE_CATEGORY.distribution);
      setMetaData({
        data: {
          document_for_customer,
          document_of,
          document_date,
          document_reference,
        },
      });

      setSubCategory({
        category: matchData.category,
        introduction: document_introduction,
      });

      setSummaryData({ data: document_summary });

      setAnalysisData({
        analysis: {
          signing_the_contract: comments_signing_the_contract,
          validity: comments_validity,
          extension: comments_extension,
          renewal: comments_renewal,
          annual_adjustment: comments_annual_adjustment,
          subcontracting: comments_subcontracting,
        },
      });

      setCommentsData({
        budget_available: administrative_basis_budget_available,
        guarantee_of_seriousness_offer:
          administrative_basis_guarantee_of_seriousness_offer,
        guarantee_of_faithful_compliance:
          administrative_basis_guarantee_of_faithful_compliance,
        fines: administrative_basis_fines,
        early_termination: administrative_basis_early_termination,
        pay: administrative_basis_pay,
      });

      setTechnicalCommentsData({
        data: {
          applicable_standards: technical_basis_applicable_standards,
          required_resources: technical_basis_required_resources,
          trainings: technical_basis_trainings,
          average_monthly_consumption:
            technical_basis_average_monthly_consumption,
          emergency_service: technical_basis_emergency_service,
          corrective_maintenance: technical_basis_corrective_maintenance,
          technical_basis_additional_obligations:
            technical_basis_additional_obligations,
        },
      });
    } else {
      const {
        document_for_customer,
        document_of,
        document_id,
        document_date,
        document_reference,
        document_introduction,
        document_summary,
        comments_renewal,
        comments_extension,
        comments_subcontracting,
        comments_re_adjustability,
        comments_price,
        comments_intellectual_property,
        comments_deadlines,
        administrative_basis_guarantee_of_seriousness_offer,
        administrative_bases_offer_validity,
        administrative_bases_scope,
        administrative_bases_termination_of_contract,
        administrative_bases_confidentiality,
        administrative_bases_payment_term,
        administrative_bases_fines,
        administrative_bases_extension_of_the_contract_term,
        administrative_bases_early_termination,
        administrative_bases_outsourcing,
        administrative_bases_method_of_payment,
        administrative_bases_information_security,
        administrative_bases_ownership_of_information,
        technical_bases_technical_requirements,
        technical_bases_maintenance,
        technical_bases_execution_deadlines,
        technical_bases_staff_required,
      } = matchData;
      setSaveDocumentID(document_id);
      setCategory(FILE_CATEGORY.general);
      setMetaData({
        data: {
          document_for_customer,
          document_of,
          document_date,
          document_reference,
        },
      });

      setSubCategory({
        category: matchData.category,
        introduction: document_introduction,
      });

      setSummaryData({ data: document_summary });
      setAnalysisData({
        analysis: {
          [C3Analysis.Renewal]: comments_renewal || null,
          [C3Analysis.Extension]: comments_extension || null,
          [C3Analysis.Re_adjustability]: comments_re_adjustability || null,
          [C3Analysis.Subcontracting]: comments_subcontracting || null,
          [C3Analysis.Price]: comments_price || null,
          [C3Analysis.Intellectual_property]:
            comments_intellectual_property || null,
          [C3Analysis.Deadlines]: comments_deadlines || null,
        },
      });

      setCommentsData({
        [C3Comments.Guarantee_of_Seriousness_Offer]:
          administrative_basis_guarantee_of_seriousness_offer || null,
        [C3Comments.Offer_Validity]:
          administrative_bases_offer_validity || null,
        [C3Comments.Scope]: administrative_bases_scope || null,
        [C3Comments.Termination_of_Contract]:
          administrative_bases_termination_of_contract || null,
        [C3Comments.Confidentiality]:
          administrative_bases_confidentiality || null,
        [C3Comments.Payment_Term]: administrative_bases_payment_term || null,
        [C3Comments.Fines]: administrative_bases_fines || null,
        [C3Comments.Extension_of_the_Contract_Term]:
          administrative_bases_extension_of_the_contract_term || null,
        [C3Comments.Early_Termination]:
          administrative_bases_early_termination || null,
        [C3Comments.Outsourcing]: administrative_bases_outsourcing || null,
        [C3Comments.Method_of_Payment]:
          administrative_bases_method_of_payment || null,
        [C3Comments.Information_Security]:
          administrative_bases_information_security || null,
        [C3Comments.Ownership_of_Information]:
          administrative_bases_ownership_of_information || null,
      });

      // Setting Technical Comments Data
      setTechnicalCommentsData({
        data: {
          [C3TechnicalComments.Technical_Requirements]:
            technical_bases_technical_requirements || null,
          [C3TechnicalComments.Maintenance]:
            technical_bases_maintenance || null,
          [C3TechnicalComments.Execution_Deadlines]:
            technical_bases_execution_deadlines || null,
          [C3TechnicalComments.Staff_Required]:
            technical_bases_staff_required || null,
        },
      });
    }
  }, [matchData]);

  const getSubCategory = async () => {
    if (!UploadFileID) {
      toast.error(FILE_UPLOAD_REQUIRED_MESSAGE);
      return;
    } else {
      setIsAnalyze(true);
      let response = await fetchSubCategory();
      if (response?.data?.data) {
        setSubCategory(response?.data?.data);
        setCategory(response?.data?.data?.category);
        if (response?.data?.data?.category === FILE_CATEGORY.distribution) {
          try {
            setIsAnalyze(true);
            await Promise.all([
              fetchAnalysisData(FILE_CATEGORY.distribution),
              fetchCommentsData(FILE_CATEGORY.distribution),
              fetchSummaryData(),
              fetchTechnicalComments(FILE_CATEGORY.distribution),
              fetchMetaData(),
            ]);
          } catch (err) {
            toast.error(FETCH_ERROR);
          } finally {
            setIsAnalyze(false);
          }
        } else if (response?.data?.data?.category === FILE_CATEGORY.it) {
          try {
            setIsAnalyze(true);
            await Promise.all([
              fetchMetaData(),
              fetchAnalysisData(FILE_CATEGORY.it),
              fetchCommentsData(FILE_CATEGORY.it),
              fetchSummaryData(),
              fetchTechnicalComments(FILE_CATEGORY.it),
            ]);
          } catch (err) {
            toast.error(FETCH_ERROR);
          } finally {
            setIsAnalyze(false);
          }
        } else if (response?.data?.data?.category === FILE_CATEGORY.general) {
          try {
            setIsAnalyze(true);
            await Promise.all([
              fetchMetaData(),
              fetchAnalysisData(FILE_CATEGORY.general),
              fetchCommentsData(FILE_CATEGORY.general),
              fetchSummaryData(),
              fetchTechnicalComments(FILE_CATEGORY.general),
            ]);
          } catch (err) {
            toast.error(FETCH_ERROR);
          } finally {
            setIsAnalyze(false);
          }
        }
      } else {
        toast.error(response?.data?.error);
        setIsAnalyze(false);
      }
    }
  };

  // Data Fetcher Function for Distribution Starts
  const fetchSubCategory = useCallback(async () => {
    if (!UploadFileID) return;
    try {
      const response = await axios.get(`${BASE_URL}/open_document/category`, {
        params: { file_id: UploadFileID },
        headers: { Authorization: `Bearer ${token}` },
      });
      return response;
    } catch (error) {
      toast.error(FETCH_ERROR);
    }
  }, [UploadFileID, token]);

  const fetchAnalysisData = useCallback(
    async (category) => {
      if (!UploadFileID) return;
      try {
        setIsAnalyze(true);

        let url =
          category === FILE_CATEGORY.it
            ? `${BASE_URL}/open_document/c2/analysis`
            : category === FILE_CATEGORY.distribution
            ? `${BASE_URL}/open_document/analysis`
            : `${BASE_URL}/open_document/c3/analysis`;
        const response = await axios.get(url, {
          params: { file_id: UploadFileID },
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log("ANALYSIS DATA", response.data.data);
        setAnalysisData(response.data.data);
      } catch (error) {
        toast.error(FETCH_ERROR);
      } finally {
        setIsAnalyze(false);
      }
    },
    [UploadFileID, token]
  );

  const fetchCommentsData = useCallback(
    async (category) => {
      if (!UploadFileID) return;
      try {
        setIsAnalyze(true);
        let url =
          category === FILE_CATEGORY.it
            ? `${BASE_URL}/open_document/c2/comments`
            : category === FILE_CATEGORY.distribution
            ? `${BASE_URL}/open_document/comments`
            : `${BASE_URL}/open_document/c3/comments`;

        const response = await axios.get(url, {
          params: { file_id: UploadFileID },
          headers: { Authorization: `Bearer ${token}` },
        });
        setCommentsData(response.data.data);
      } catch (error) {
        toast.error(FETCH_ERROR);
      } finally {
        setIsAnalyze(false);
      }
    },
    [UploadFileID, token]
  );
  const fetchSummaryData = useCallback(async () => {
    if (!UploadFileID) return;
    try {
      setIsAnalyze(true);
      const response = await axios.get(`${BASE_URL}/open_document/summary`, {
        params: { file_id: UploadFileID },
        headers: { Authorization: `Bearer ${token}` },
      });
      setSummaryData(response.data);
    } catch (error) {
      toast.error(FETCH_ERROR);
    } finally {
      setIsAnalyze(false);
    }
  }, [UploadFileID, token]);

  const fetchTechnicalComments = useCallback(
    async (category) => {
      if (!UploadFileID) return;
      try {
        setIsAnalyze(true);
        let url =
          category === FILE_CATEGORY.it
            ? `${BASE_URL}/open_document/c2/technical_comments`
            : category === FILE_CATEGORY.distribution
            ? `${BASE_URL}/open_document/technical_comments`
            : `${BASE_URL}/open_document/c3/technical_comments`;
        const response = await axios.get(url, {
          params: { file_id: UploadFileID },
          headers: { Authorization: `Bearer ${token}` },
        });
        setTechnicalCommentsData(response.data);
      } catch (error) {
        toast.error(FETCH_ERROR);
      } finally {
        setIsAnalyze(false);
      }
    },
    [UploadFileID, token]
  );

  const fetchMetaData = useCallback(async () => {
    if (!UploadFileID) return;
    try {
      setIsAnalyze(true);
      const response = await axios.get(`${BASE_URL}/open_document/metadata`, {
        params: { file_id: UploadFileID },
        headers: { Authorization: `Bearer ${token}` },
      });
      const additionalData = {
        document_of: "AltLegal",
        document_date: new Date().toISOString().split("T")[0],
      };

      const mergedData = {
        data: {
          ...response.data.data,
          ...additionalData,
        },
      };
      setMetaData(mergedData);
    } catch (error) {
      toast.error(FETCH_ERROR);
    } finally {
      setIsAnalyze(false);
    }
  }, [UploadFileID, token]);
  // Data Fetcher Function for Distribution Ends

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setIsLoading(true);
      const file = acceptedFiles[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        try {
          const response = await axios.post(
            `${BASE_URL}/open_document/upload/`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          setUploadFileID(response.data.file_id);
          setUploadFileName(response.data.filename);
          toast.success(FILE_UPLOAD_SUCCESS_MESSAGE);
        } catch (error) {
          toast.error(FILE_UPLOAD_ERROR_MESSAGE);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [token]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    onDrop,
  });

  const stripIdFromFilename = (filename) => {
    const idPattern = new RegExp(`^${UploadFileID}-`);
    let newFilename = filename.replace(idPattern, "");

    const yearPattern = new RegExp(`^202[0-9]_`);
    newFilename = newFilename.replace(yearPattern, "");

    return newFilename;
  };

  const handleAnalysisChange = (key, value) => {
    setAnalysisData((prevState) => ({
      ...prevState,
      analysis: {
        ...prevState.analysis,
        [key]: value,
      },
    }));
  };
  const handleTechnicalCommentsChange = (key, value) => {
    setTechnicalCommentsData((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [key]: value,
      },
    }));
  };
  const handleMetaDataChange = (key, value) => {
    setMetaData((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [key]: value,
      },
    }));
  };
  const handleCommentsChange = (key, value) => {
    setCommentsData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleAccordionToggle = (key) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [key]: !prevExpanded[key],
    }));
  };

  const handleEditButtonClick = (key) => {
    setIsEdit(key);
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [key]: true,
    }));
  };

  const handleSaveDocument = async () => {
    let bodyData;

    if (category === FILE_CATEGORY.general) {
      bodyData = {
        ...metaData.data,
        document_introduction: Array.isArray(subCategory?.introduction)
          ? subCategory.introduction.join("")
          : subCategory?.introduction || "",
        document_summary: summaryData.data,

        comments_re_adjustability: AnalysisData.analysis.re_adjustability,
        comments_deadlines: AnalysisData.analysis.deadlines,
        comments_extension: AnalysisData.analysis.extension,
        comments_renewal: AnalysisData.analysis.renewal,
        comments_subcontracting: AnalysisData.analysis.subcontracting,
        comments_intellectual_property:
          AnalysisData.analysis.intellectual_property,
        comments_price: AnalysisData.analysis.price,

        administrative_bases_offer_validity: commentsData.offer_validity,
        administrative_basis_guarantee_of_seriousness_offer:
          commentsData.guarantee_of_seriousness_offer,
        administrative_bases_scope: commentsData.scope,
        administrative_bases_termination_of_contract:
          commentsData.termination_of_contract,
        administrative_bases_confidentiality: commentsData.confidentiality,
        administrative_bases_payment_term: commentsData.payment_term,
        administrative_bases_fines: commentsData.fines,
        administrative_bases_extension_of_the_contract_term:
          commentsData.extension_of_the_contract_term,
        administrative_bases_early_termination: commentsData.early_termination,
        administrative_bases_outsourcing: commentsData.outsourcing,
        administrative_bases_method_of_payment: commentsData.method_of_payment,
        administrative_bases_information_security:
          commentsData.information_security,
        administrative_bases_ownership_of_information:
          commentsData.ownership_of_information,

        technical_bases_technical_requirements:
          technicalCommentsData.data.technical_requirements,
        technical_bases_maintenance: technicalCommentsData.data.maintenance,
        technical_bases_execution_deadlines:
          technicalCommentsData.data.execution_deadlines,
        technical_bases_staff_required:
          technicalCommentsData.data.staff_required,
      };
    } else if (category === FILE_CATEGORY.distribution) {
      bodyData = {
        ...metaData.data,
        document_introduction: Array.isArray(subCategory?.introduction)
          ? subCategory.introduction.join("")
          : subCategory?.introduction || "",
        document_summary: summaryData.data,
        comments_signing_the_contract:
          AnalysisData.analysis.signing_the_contract,
        comments_validity: AnalysisData.analysis.validity,
        comments_extension: AnalysisData.analysis.extension,
        comments_renewal: AnalysisData.analysis.renewal,
        comments_annual_adjustment: AnalysisData.analysis.annual_adjustment,
        comments_subcontracting: AnalysisData.analysis.subcontracting,
        administrative_basis_budget_available: commentsData.budget_available,
        administrative_basis_guarantee_of_seriousness_offer:
          commentsData.guarantee_of_seriousness_offer,
        administrative_basis_guarantee_of_faithful_compliance:
          commentsData.guarantee_of_faithful_compliance,
        administrative_basis_fines: commentsData.fines,
        administrative_basis_early_termination: commentsData.early_termination,
        administrative_basis_pay: commentsData.pay,
        technical_basis_applicable_standards:
          technicalCommentsData.data.applicable_standards,
        technical_basis_required_resources:
          technicalCommentsData.data.required_resources,
        technical_basis_trainings: technicalCommentsData.data.trainings,
        technical_basis_average_monthly_consumption:
          technicalCommentsData.data.average_monthly_consumption,
        technical_basis_emergency_service:
          technicalCommentsData.data.emergency_service,
        technical_basis_corrective_maintenance:
          technicalCommentsData.data.corrective_maintenance,
        technical_basis_additional_obligations:
          technicalCommentsData.data.applicable_standards,
      };
    } else if (category === FILE_CATEGORY.it) {
      bodyData = {
        ...metaData.data,
        document_introduction: Array.isArray(subCategory?.introduction)
          ? subCategory.introduction.join("")
          : subCategory?.introduction || "",
        document_summary: summaryData.data,

        comments_subscription_of_the_contract:
          AnalysisData.analysis.subscription_of_the_contract,
        comments_validity: AnalysisData.analysis.validity,
        comments_re_adjustability: AnalysisData.analysis.re_adjustability,
        comments_deadlines: AnalysisData.analysis.deadlines,
        comments_extension: AnalysisData.analysis.extension,
        comments_renewal: AnalysisData.analysis.renewal,
        comments_subcontracting: AnalysisData.analysis.subcontracting,
        comments_intellectual_property:
          AnalysisData.analysis.intellectual_property,
        comments_price: AnalysisData.analysis.price,

        administrative_bases_offer_validity: commentsData.offer_validity,
        administrative_basis_guarantee_of_seriousness_offer:
          commentsData.guarantees,
        administrative_bases_scope: commentsData.scope,
        administrative_bases_termination_of_contract:
          commentsData.termination_of_contract,
        administrative_bases_confidentiality: commentsData.confidentiality,
        administrative_bases_payment_term: commentsData.payment_term,
        administrative_bases_fines: commentsData.fines,
        administrative_bases_extension_of_the_contract_term:
          commentsData.extension_of_the_contract_term,
        administrative_bases_early_termination: commentsData.early_termination,
        administrative_bases_outsourcing: commentsData.outsourcing,
        administrative_bases_method_of_payment: commentsData.method_of_payment,
        administrative_bases_information_security:
          commentsData.information_security,
        administrative_bases_ownership_of_information:
          commentsData.ownership_of_information,
        administrative_bases_estimated_contract_amount:
          commentsData.estimated_contract_amount,
        administrative_bases_guarantees: commentsData.guarantees,
        administrative_bases_budget_modification:
          commentsData.budget_modification,
        administrative_bases_presentation_of_warranty:
          commentsData.presentation_of_warranty,
        administrative_bases_evaluation_criteria:
          commentsData.evaluation_criteria,
        technical_bases_training_plan: technicalCommentsData.data.training_plan,
        technical_bases_technical_requirements:
          technicalCommentsData.data.technical_requirements,
        technical_bases_maintenance: technicalCommentsData.data.maintenance,
        technical_bases_execution_deadlines:
          technicalCommentsData.data.execution_deadlines,
        technical_bases_staff_required:
          technicalCommentsData.data.staff_required,
      };
    }
    try {
      setIsAnalyze(true);
      let categorySegment;

      if (category === FILE_CATEGORY.it) {
        categorySegment = "c2";
      } else if (category === FILE_CATEGORY.distribution) {
        categorySegment = "c1";
      } else {
        categorySegment = "c3";
      }
      let response;
      if (saveDocumentID) {
        response = await axios.patch(
          `${BASE_URL}/directory/update/${categorySegment}/${saveDocumentID}`,
          bodyData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else {
        response = await axios.post(
          `${BASE_URL}/directory/create/${categorySegment}`,
          bodyData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setSaveDocumentID(response.data.document_id);
      }

      toast.success("Document Saved Successfully");
      getSaveDoc(response.data.document_id);
    } catch (error) {
      toast.error(FETCH_ERROR);
    } finally {
      setIsAnalyze(false);
    }
  };
  const getSaveDoc = async (ID) => {
    try {
      setIsAnalyze(true);
      let categorySegment;

      if (category === FILE_CATEGORY.it) {
        categorySegment = "c2";
      } else if (category === FILE_CATEGORY.distribution) {
        categorySegment = "c1";
      } else {
        categorySegment = "c3";
      }

      const response = await axios.get(
        `${BASE_URL}/directory/${categorySegment}/${ID}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const data = response.data;

      if (category === FILE_CATEGORY.it) {
        const {
          document_for_customer,
          document_of,
          document_date,
          document_reference,
          document_introduction,
          document_summary,
          comments_subscription_of_the_contract,
          comments_validity,
          comments_re_adjustability,
          comments_deadlines,
          comments_extension,
          comments_renewal,
          comments_subcontracting,
          comments_intellectual_property,
          comments_price,
          administrative_bases_offer_validity,
          administrative_bases_guarantees,
          administrative_bases_scope,
          administrative_bases_termination_of_contract,
          administrative_bases_confidentiality,
          administrative_bases_payment_term,
          administrative_bases_fines,
          administrative_bases_extension_of_the_contract_term,
          administrative_bases_early_termination,
          administrative_bases_outsourcing,
          administrative_bases_method_of_payment,
          administrative_bases_information_security,
          administrative_bases_ownership_of_information,
          administrative_bases_estimated_contract_amount,
          administrative_bases_budget_modification,
          administrative_bases_presentation_of_warranty,
          administrative_bases_evaluation_criteria,
          technical_bases_training_plan,
          technical_bases_technical_requirements,
          technical_bases_maintenance,
          technical_bases_execution_deadlines,
          technical_bases_staff_required,
        } = data;

        setMetaData({
          data: {
            document_for_customer,
            document_of,
            document_date,
            document_reference,
          },
        });

        setSubCategory({
          ...subCategory,
          introduction: document_introduction,
        });

        setSummaryData({ data: document_summary });

        setAnalysisData({
          analysis: {
            subscription_of_the_contract: comments_subscription_of_the_contract,
            validity: comments_validity,
            re_adjustability: comments_re_adjustability,
            deadlines: comments_deadlines,
            extension: comments_extension,
            renewal: comments_renewal,
            subcontracting: comments_subcontracting,
            intellectual_property: comments_intellectual_property,
            price: comments_price,
          },
        });

        setCommentsData({
          offer_validity: administrative_bases_offer_validity,
          guarantees: administrative_bases_guarantees,
          scope: administrative_bases_scope,
          termination_of_contract: administrative_bases_termination_of_contract,
          confidentiality: administrative_bases_confidentiality,
          payment_term: administrative_bases_payment_term,
          fines: administrative_bases_fines,
          extension_of_the_contract_term:
            administrative_bases_extension_of_the_contract_term,
          early_termination: administrative_bases_early_termination,
          outsourcing: administrative_bases_outsourcing,
          method_of_payment: administrative_bases_method_of_payment,
          information_security: administrative_bases_information_security,
          ownership_of_information:
            administrative_bases_ownership_of_information,
          estimated_contract_amount:
            administrative_bases_estimated_contract_amount,
          budget_modification: administrative_bases_budget_modification,
          presentation_of_warranty:
            administrative_bases_presentation_of_warranty,
          evaluation_criteria: administrative_bases_evaluation_criteria,
        });

        setTechnicalCommentsData({
          data: {
            training_plan: technical_bases_training_plan,
            technical_requirements: technical_bases_technical_requirements,
            maintenance: technical_bases_maintenance,
            execution_deadlines: technical_bases_execution_deadlines,
            staff_required: technical_bases_staff_required,
          },
        });
      } else if (category === FILE_CATEGORY.distribution) {
        const {
          document_for_customer,
          document_of,
          document_date,
          document_reference,
          document_introduction,
          document_summary,
          comments_signing_the_contract,
          comments_validity,
          comments_extension,
          comments_renewal,
          comments_annual_adjustment,
          comments_subcontracting,
          administrative_basis_budget_available,
          administrative_basis_guarantee_of_seriousness_offer,
          administrative_basis_guarantee_of_faithful_compliance,
          administrative_basis_fines,
          administrative_basis_early_termination,
          administrative_basis_pay,
          technical_basis_applicable_standards,
          technical_basis_required_resources,
          technical_basis_trainings,
          technical_basis_average_monthly_consumption,
          technical_basis_emergency_service,
          technical_basis_corrective_maintenance,
          technical_basis_additional_obligations,
        } = data;
        setMetaData({
          data: {
            document_for_customer,
            document_of,
            document_date,
            document_reference,
          },
        });

        setSubCategory({
          ...subCategory,
          introduction: document_introduction,
        });

        setSummaryData({ data: document_summary });

        setAnalysisData({
          analysis: {
            signing_the_contract: comments_signing_the_contract,
            validity: comments_validity,
            extension: comments_extension,
            renewal: comments_renewal,
            annual_adjustment: comments_annual_adjustment,
            subcontracting: comments_subcontracting,
          },
        });

        setCommentsData({
          budget_available: administrative_basis_budget_available,
          guarantee_of_seriousness_offer:
            administrative_basis_guarantee_of_seriousness_offer,
          guarantee_of_faithful_compliance:
            administrative_basis_guarantee_of_faithful_compliance,
          fines: administrative_basis_fines,
          early_termination: administrative_basis_early_termination,
          pay: administrative_basis_pay,
        });

        setTechnicalCommentsData({
          data: {
            applicable_standards: technical_basis_applicable_standards,
            required_resources: technical_basis_required_resources,
            trainings: technical_basis_trainings,
            average_monthly_consumption:
              technical_basis_average_monthly_consumption,
            emergency_service: technical_basis_emergency_service,
            corrective_maintenance: technical_basis_corrective_maintenance,
            technical_basis_additional_obligations:
              technical_basis_additional_obligations,
          },
        });
      } else {
        const {
          document_for_customer,
          document_of,
          document_date,
          document_reference,
          document_introduction,
          document_summary,
          comments_renewal,
          comments_extension,
          comments_subcontracting,
          comments_re_adjustability,
          comments_price,
          comments_intellectual_property,
          comments_deadlines,
          administrative_basis_guarantee_of_seriousness_offer,
          administrative_bases_offer_validity,
          administrative_bases_scope,
          administrative_bases_termination_of_contract,
          administrative_bases_confidentiality,
          administrative_bases_payment_term,
          administrative_bases_fines,
          administrative_bases_extension_of_the_contract_term,
          administrative_bases_early_termination,
          administrative_bases_outsourcing,
          administrative_bases_method_of_payment,
          administrative_bases_information_security,
          administrative_bases_ownership_of_information,
          technical_bases_technical_requirements,
          technical_bases_maintenance,
          technical_bases_execution_deadlines,
          technical_bases_staff_required,
        } = data;

        console.log("DATA", data);
        setMetaData({
          data: {
            document_for_customer,
            document_of,
            document_date,
            document_reference,
          },
        });

        setSubCategory({
          ...subCategory,
          introduction: document_introduction,
        });

        setSummaryData({ data: document_summary });
        setAnalysisData({
          analysis: {
            [C3Analysis.renewal]: comments_renewal || null,
            [C3Analysis.extension]: comments_extension || null,
            [C3Analysis.re_adjustability]: comments_re_adjustability || null,
            [C3Analysis.subcontracting]: comments_subcontracting || null,
            [C3Analysis.price]: comments_price || null,
            [C3Analysis.intellectual_property]:
              comments_intellectual_property || null,
            [C3Analysis.deadlines]: comments_deadlines || null,
          },
        });

        setCommentsData({
          [C3Comments.guarantee_of_seriousness_offer]:
            administrative_basis_guarantee_of_seriousness_offer || null,
          [C3Comments.offer_validity]:
            administrative_bases_offer_validity || null,
          [C3Comments.scope]: administrative_bases_scope || null,
          [C3Comments.termination_of_contract]:
            administrative_bases_termination_of_contract || null,
          [C3Comments.confidentiality]:
            administrative_bases_confidentiality || null,
          [C3Comments.payment_term]: administrative_bases_payment_term || null,
          [C3Comments.fines]: administrative_bases_fines || null,
          [C3Comments.extension_of_the_contract_term]:
            administrative_bases_extension_of_the_contract_term || null,
          [C3Comments.early_termination]:
            administrative_bases_early_termination || null,
          [C3Comments.outsourcing]: administrative_bases_outsourcing || null,
          [C3Comments.method_of_payment]:
            administrative_bases_method_of_payment || null,
          [C3Comments.information_security]:
            administrative_bases_information_security || null,
          [C3Comments.ownership_of_information]:
            administrative_bases_ownership_of_information || null,
        });

        // Setting Technical Comments Data
        setTechnicalCommentsData({
          data: {
            [C3TechnicalComments.technical_requirements]:
              technical_bases_technical_requirements || null,
            [C3TechnicalComments.maintenance]:
              technical_bases_maintenance || null,
            [C3TechnicalComments.execution_deadlines]:
              technical_bases_execution_deadlines || null,
            [C3TechnicalComments.staff_required]:
              technical_bases_staff_required || null,
          },
        });
      }
    } catch {
      toast.error(FETCH_ERROR);
    } finally {
      setIsAnalyze(false);
    }
  };
  const handleExportDocs = async () => {
    let url = "";

    if (saveDocumentID) {
      if (category === FILE_CATEGORY.distribution) {
        url = `${BASE_URL}/directory/export/${saveDocumentID}`;
      } else if (category === FILE_CATEGORY.it) {
        url = `${BASE_URL}/directory/export/c2/${saveDocumentID}`;
      } else if (category === FILE_CATEGORY.general) {
        url = `${BASE_URL}/directory/export/c3/${saveDocumentID}`;
      }

      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        });

        const file = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);

        const fileName =
          response.headers["content-disposition"]
            ?.split("filename=")[1]
            ?.replace(/"/g, "") || "exported_file";

        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        link.parentNode.removeChild(link);
      } catch (err) {
        console.log(err);
        toast.error(FETCH_ERROR);
      }
    } else {
      toast.error("Something went wrong");
    }
  };

  const formatString = (input) => {
    return input
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <>
      <Header />
      <Box className={classes.MainContainer}>
        <Sidebar />
        <Box className={classes.DashboardContainer}>
          <Box className={classes.TopHeader}>
            <Box className={classes.Breadcrumbs}>
              <Typography className={classes.BreadcrumbsText}>
                Analyze Document
              </Typography>
            </Box>
          </Box>
          <Box className={classes.ImportBtn} {...getRootProps()}>
            <input {...getInputProps()} />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={open}
            >
              {isLoading ? (
                <CustomLoader loading={isLoading} color="#fff" size={25} />
              ) : (
                <>Import document</>
              )}
            </Button>
          </Box>

          {/* details */}
          {(uploadFileName.length > 0 || subCategory || summaryData) && (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                "& > :not(style)": {
                  mt: 10,
                  width: "96%",
                  maxWidth: "100%",
                  minHeight: 128,
                  height: "auto",
                },
              }}
            >
              <Box>
                <Typography sx={{ py: 2 }} variant="h2">
                  File Details
                </Typography>
                <Paper sx={{ padding: 2 }}>
                  {uploadFileName.length > 0 && (
                    <Typography variant="h6">
                      <span style={{ fontWeight: "bold" }}>Filename:</span>{" "}
                      {stripIdFromFilename(uploadFileName)}
                    </Typography>
                  )}
                  {subCategory && (
                    <>
                      <Typography variant="h6">
                        <span style={{ fontWeight: "bold" }}>Category:</span>{" "}
                        {subCategory?.category}
                      </Typography>
                      <Typography variant="h6">
                        <span style={{ fontWeight: "bold" }}>
                          Introduction:
                        </span>{" "}
                        {subCategory?.introduction}
                      </Typography>
                    </>
                  )}
                  {summaryData && (
                    <Typography variant="h6">
                      <span style={{ fontWeight: "bold" }}>Summary:</span>{" "}
                      {summaryData.data}
                    </Typography>
                  )}
                </Paper>
              </Box>
            </Box>
          )}

          {metaData && (
            <Box sx={{ padding: "20px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ py: 2 }} variant="h2">
                  Metadata
                </Typography>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ width: "10px", height: "40px" }}
                  onClick={fetchMetaData}
                >
                  <Refresh />
                </Button>
              </Box>
              {Object.entries(MetaDataObj).map(([key, value]) => (
                <Accordion
                  key={key}
                  expanded={expanded[key] !== false}
                  onChange={() => handleAccordionToggle(key)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${key}-content`}
                    id={`${key}-header`}
                  >
                    <Box className={classes.AnalysisContainer}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {value}
                      </Typography>
                      {!isEdit || isEdit !== key ? (
                        <Button
                          className={classes.EditBtn}
                          variant="outlined"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditButtonClick(key);
                          }}
                        >
                          Edit
                        </Button>
                      ) : (
                        <Button
                          color={"success"}
                          variant="outlined"
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsEdit("");
                          }}
                        >
                          Save
                        </Button>
                      )}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {isEdit === key ? (
                      <TextField
                        sx={{ width: "100%" }}
                        multiline
                        rows={2}
                        variant="outlined"
                        color="info"
                        value={metaData.data[key]}
                        onChange={(e) =>
                          handleMetaDataChange(key, e.target.value)
                        }
                      />
                    ) : (
                      <Typography>{metaData && metaData.data[key]}</Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          )}
          {AnalysisData && (
            <Box sx={{ padding: "20px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ py: 2 }} variant="h2">
                  Analysis
                </Typography>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ width: "10px", height: "40px" }}
                  onClick={() => fetchAnalysisData(category)}
                >
                  <Refresh />
                </Button>
              </Box>
              {Object.entries(
                category === FILE_CATEGORY.distribution
                  ? Analysis
                  : category === FILE_CATEGORY.it
                  ? C2Analysis
                  : C3Analysis
              ).map(([key, value]) => (
                <Accordion
                  key={key}
                  expanded={expanded[key] !== false}
                  onChange={() => handleAccordionToggle(key)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${key}-content`}
                    id={`${key}-header`}
                  >
                    <Box className={classes.AnalysisContainer}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {formatString(value)}
                      </Typography>
                      {!isEdit || isEdit !== key ? (
                        <Button
                          className={classes.EditBtn}
                          variant="outlined"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditButtonClick(key);
                          }}
                        >
                          Edit
                        </Button>
                      ) : (
                        <Button
                          color={"success"}
                          variant="outlined"
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsEdit("");
                          }}
                        >
                          Save
                        </Button>
                      )}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {isEdit === key ? (
                      <TextField
                        sx={{ width: "100%" }}
                        multiline
                        rows={2}
                        variant="outlined"
                        color="info"
                        value={AnalysisData.analysis[key]}
                        onChange={(e) =>
                          handleAnalysisChange(key, e.target.value)
                        }
                      />
                    ) : (
                      <Typography>
                        {AnalysisData && AnalysisData.analysis[key]}
                      </Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          )}

          {commentsData && (
            <Box sx={{ padding: "20px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ py: 2 }} variant="h2">
                  Comments
                </Typography>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ width: "10px", height: "40px" }}
                  onClick={() => fetchCommentsData(category)}
                >
                  <Refresh />
                </Button>
              </Box>
              {Object.entries(
                category === FILE_CATEGORY.distribution
                  ? Comments
                  : category === FILE_CATEGORY.it
                  ? C2Comments
                  : C3Comments
              ).map(([key, value]) => (
                <Accordion
                  key={key}
                  expanded={expanded[key] !== false}
                  onChange={() => handleAccordionToggle(key)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${key}-content`}
                    id={`${key}-header`}
                  >
                    <Box className={classes.AnalysisContainer}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {formatString(value)}
                      </Typography>
                      {!isEdit || isEdit !== key ? (
                        <Button
                          className={classes.EditBtn}
                          variant="outlined"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditButtonClick(key);
                          }}
                        >
                          Edit
                        </Button>
                      ) : (
                        <Button
                          color={"success"}
                          variant="outlined"
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsEdit("");
                          }}
                        >
                          Save
                        </Button>
                      )}
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails>
                    {isEdit === key ? (
                      <TextField
                        sx={{ width: "100%" }}
                        multiline
                        rows={2}
                        variant="outlined"
                        color="info"
                        value={commentsData?.[key]}
                        onChange={(e) =>
                          handleCommentsChange(key, e.target.value)
                        }
                      />
                    ) : (
                      <Typography>
                        {commentsData && commentsData?.[key]}
                      </Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          )}

          {technicalCommentsData && (
            <Box sx={{ padding: "20px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ py: 2 }} variant="h2">
                  Technical Comments
                </Typography>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ width: "10px", height: "40px" }}
                  onClick={() => fetchTechnicalComments(category)}
                >
                  <Refresh />
                </Button>
              </Box>
              {Object.entries(
                category === FILE_CATEGORY.distribution
                  ? TechnicalComments
                  : category === FILE_CATEGORY.it
                  ? C2TechnicalComments
                  : C3TechnicalComments
              ).map(([key, value]) => (
                <Accordion
                  key={key}
                  expanded={expanded[key] !== false}
                  onChange={() => handleAccordionToggle(key)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${key}-content`}
                    id={`${key}-header`}
                  >
                    <Box className={classes.AnalysisContainer}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {formatString(value)}
                      </Typography>
                      {!isEdit || isEdit !== key ? (
                        <Button
                          className={classes.EditBtn}
                          variant="outlined"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditButtonClick(key);
                          }}
                        >
                          Edit
                        </Button>
                      ) : (
                        <Button
                          color={"success"}
                          variant="outlined"
                          onClick={(e) => {
                            e.stopPropagation();
                            setIsEdit("");
                          }}
                        >
                          Save
                        </Button>
                      )}
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {isEdit === key ? (
                      <TextField
                        sx={{ width: "100%" }}
                        multiline
                        rows={2}
                        variant="outlined"
                        color="info"
                        value={technicalCommentsData.data[key]}
                        onChange={(e) =>
                          handleTechnicalCommentsChange(key, e.target.value)
                        }
                      />
                    ) : (
                      <Typography>
                        {technicalCommentsData &&
                          technicalCommentsData.data[key]}
                      </Typography>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          )}
          <Box className={classes.TopHeader}>
            <Box></Box>
            {metaData &&
              AnalysisData &&
              commentsData &&
              technicalCommentsData && (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="success"
                  className={classes.button}
                  onClick={handleSaveDocument}
                >
                  <>Save document</>
                </Button>
              )}
          </Box>
        </Box>
        <RightSidebar
          getSubCategory={getSubCategory}
          handleExportDocs={handleExportDocs}
          metaData={metaData}
        />
      </Box>
      <Backdrop className={classes.backdrop} open={isAnalyze}>
        <CustomLoader loading={isAnalyze} size={50} />
      </Backdrop>
      <ToastContainer />
    </>
  );
};

export default Dashboard;
