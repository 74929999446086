import React, { useCallback, useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "./Sidebar";
import {
  Box,
  IconButton,
  Typography,
  Button,
  Backdrop,
  Modal,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Tooltip,
} from "@mui/material";
import theme from "../../theme";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { PromptModal } from "./PromptModal";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid } from "@mui/x-data-grid";
import { BASE_URL } from "../../Constants/Constants";
import axios from "axios";
import { getToken } from "../../utils/utils";
import { toast, ToastContainer } from "react-toastify";
import CustomLoader from "../../utils/CustomLoader";
import {
  FETCH_ERROR,
  PROMPT_FIELD_REQUIRED_MESSAGE,
  PROMPT_CREATE_SUCCESS_MESSAGE,
  PROMPT_DELETE_SUCCESS_MESSAGE,
  PROMPT_EDIT_SUCCESS_MESSAGE,
  STATUS_SELECTION_ERROR_MESSAGE,
  TYPE_SELECTION_ERROR_MESSAGE,
} from "../../utils/Validation";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { PROMPT_TYPES } from "../../Constants/Constants";

const useStyles = makeStyles((themes) => ({
  MainContainer: {
    display: "flex",
    width: "100%",
    background: "#F1F1F1",
  },
  ContentContainer: {
    width: "80%",
    padding: 20,
    height: "93vh",
    display: "flex",
    flexDirection: "column",
    background: "#fff",
  },
  Breadcrumbs: {
    display: "flex",
    gap: 4,
    alignItems: "center",
    marginBottom: "20px",
    justifyContent: "space-between",
  },
  BreadcrumbsText: {
    color: theme.palette.secondary.black,
    fontFamily: "Helvetica",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "30px",
  },
  BreadcrumbsIcon: {
    width: "24px",
    height: "24px",
  },
  tableContainer: {
    width: "100%",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: theme.palette.secondary.main,
    color: "#000",
  },
  BatchActionButton: {
    backgroundColor: "#F3F7F9 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "180px",
    height: "30px",
    padding: "7px 8.75px",
    gap: "10px",
    borderRadius: "3px 0px 0px 0px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    opacity: "0.65",
    marginBottom: "30px !important",
    textTransform: "none",
  },
  secondaryText: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.palette.secondary.black,
  },
  gridContainer: {
    padding: "20px",
  },
  ButtonContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  CreateIcon: {
    height: "40px",
  },
  CreatePromptText: {
    textTransform: "capitalize",
  },
  Modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    backgroundColor: "#fff",
    minHeight: 350,
    boxShadow: 24,
    padding: 30,
    borderRadius: 10,
  },
  TextInput: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  Btn: {
    width: 200,
  },
}));
// const label = { inputProps: { "aria-label": "Disable Switch" } };

const Prompts = () => {
  const classes = useStyles();

  const [modalOpen, setModalOpen] = useState(false);
  const [showModalOpen, setShowModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [PromptsAPIData, setPromptsAPIData] = useState(null);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [createPrompt, setCreatePrompt] = useState({
    id: null,
    type: "",
    prompt: "",
    is_active: "Active",
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleModalClose = () => {
    setSelectedPrompt("");
    setShowModalOpen(false);
    setCreatePrompt({
      id: null,
      type: "",
      prompt: "",
      is_active: null,
    });
    setModalOpen(false);
    setIsEdit(false);
  };
  const handleModalOpen = () => {
    setShowModalOpen(true);
  };
  const token = getToken();
  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${BASE_URL}/prompt/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setPromptsAPIData(response.data);
    } catch (error) {
      toast.error(FETCH_ERROR);
    } finally {
      setIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    getData();
  }, [token, getData]);

  const handleCreatePromptsChange = (e) => {
    const { name, value } = e.target;

    setCreatePrompt({ ...createPrompt, [name]: value });
  };
  const handlePromptCreateClick = async () => {
    if (!createPrompt.type) {
      toast.error(TYPE_SELECTION_ERROR_MESSAGE);
      return;
    }
    if (!createPrompt.prompt) {
      toast.error(PROMPT_FIELD_REQUIRED_MESSAGE);
      return;
    }

    if (createPrompt.is_active === null) {
      toast.error(STATUS_SELECTION_ERROR_MESSAGE);
      return;
    }

    const updatedPrompt = {
      ...createPrompt,
      is_active: createPrompt.is_active === "Active" ? true : false,
    };
    setCreatePrompt(updatedPrompt);

    try {
      setIsLoading(true);
      await axios.post(`${BASE_URL}/prompt/create`, updatedPrompt, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(PROMPT_CREATE_SUCCESS_MESSAGE);
      getData();
      setCreatePrompt({
        id: null,
        type: "",
        prompt: "",
        is_active: null,
      });
    } catch (error) {
      toast.error(FETCH_ERROR);
    } finally {
      setIsLoading(false);
      setModalOpen(false);
    }
  };
  const handlePromptEdit = async (selectedRow) => {
    setCreatePrompt({
      id: selectedRow.id,
      type: selectedRow.type,
      prompt: selectedRow.prompt,
      is_active: selectedRow.isActive === "Active" ? true : false,
    });
    setIsEdit(true);
    setModalOpen(true);
  };
  const handlePromptEditClick = async () => {
    if (!createPrompt.type) {
      toast.error(TYPE_SELECTION_ERROR_MESSAGE);
      return;
    }
    if (!createPrompt.prompt) {
      toast.error(PROMPT_FIELD_REQUIRED_MESSAGE);
      return;
    }
    try {
      setIsLoading(true);
      await axios.put(
        `${BASE_URL}/prompt/update/${createPrompt.id}`,
        createPrompt,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(PROMPT_EDIT_SUCCESS_MESSAGE);
      getData();
      setCreatePrompt({
        id: null,
        type: "",
        prompt: "",
        is_active: null,
      });
    } catch (error) {
      toast.error(FETCH_ERROR);
    } finally {
      setIsLoading(false);
      setModalOpen(false);
      setIsEdit(false);
    }
  };
  const handlePromptsDelete = async (id) => {
    try {
      setIsLoading(true);
      await axios.delete(`${BASE_URL}/prompt/delete/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(PROMPT_DELETE_SUCCESS_MESSAGE);
      getData();
    } catch (error) {
      toast.error(FETCH_ERROR);
    } finally {
      setIsLoading(false);
    }
  };
  const handleActivatePrompt = async (row) => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      await axios.put(
        `${BASE_URL}/prompt/update/${row.id}`,
        { is_active: true, prompt: row.prompt, type: row.type },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Prompt activated successfully!");
      getData();
      setIsLoading(false);
    } catch (error) {
      toast.error(FETCH_ERROR);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  const rows = PromptsAPIData?.map((item) => {
    return {
      id: item.id,
      type: item.type,
      prompt: item.prompt,
      isActive: item.is_active ? "Active" : "Inactive",
    };
  });
  const columns = [
    { field: "id", headerName: "Id", width: 90 },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      headerClassName: "tableHeaderCell",
    },
    {
      field: "prompt",
      headerName: "Prompts",
      flex: 1,
      headerClassName: "tableHeaderCell",
      renderCell: (params) => {
        return (
          <span style={{ textTransform: "capitalize" }}>{params.value}</span>
        );
      },
    },
    {
      field: "isActive",
      headerName: "Status",
      flex: 1,
      headerClassName: "tableHeaderCell",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
          <Tooltip title="Show">
            <IconButton
              color="info"
              aria-label="Show"
              onClick={() => {
                setSelectedPrompt(params.row);
                handleModalOpen();
              }}
            >
              <RemoveRedEyeIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton
              color="inherit"
              aria-label="edit"
              onClick={() => handlePromptEdit(params.row)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              color="primary"
              aria-label="delete"
              onClick={() => handlePromptsDelete(params.row.id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          {params.row.isActive === "Active" ? (
            <Tooltip title="Activated">
              <span
                style={{ color: "green", fontWeight: "bold", padding: "15px" }}
              >
                Activated
              </span>
            </Tooltip>
          ) : (
            <Tooltip title="Activate">
              <Button
                variant="contained"
                style={{ backgroundColor: "#C91618", width: "100px" }}
                aria-label="activate"
                onClick={() => handleActivatePrompt(params.row)}
                size="small"
              >
                Activate
              </Button>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ];

  return (
    <>
      <Header />
      {/* <Box className={classes.MainContainer}>
        <Sidebar />
        <Box className={classes.DashboardContainer}>
          <div>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom="20px"
            >
              <Typography variant="h6">List of Custom Prompts</Typography>
            </Box>

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Prompts</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {prompts.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>NA</TableCell>
                      <TableCell align="center">
                        <IconButton
                          onClick={() => {
                            setSelectedPrompt(row);
                            handleModalOpen();
                          }}
                          color="primary"
                          aria-label="edit"
                        >
                          <RemoveRedEyeIcon />
                        </IconButton>
                        <IconButton color="secondary" aria-label="delete">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Box>
      </Box> */}
      {/* <Button
    variant="contained"
    color="primary"
    onClick={handleModalOpen}
  >
    Add custom prompt
  </Button> */}
      <Box className={classes.MainContainer}>
        <Sidebar />
        <Box className={classes.ContentContainer}>
          <Box className={classes.Breadcrumbs}>
            <Typography className={classes.BreadcrumbsText}>
              List of Custom Prompts
            </Typography>
            <Button
              variant="contained"
              className={classes.CreateIcon}
              startIcon={<AddIcon />}
              onClick={() => setModalOpen(true)}
            >
              <Typography className={classes.CreatePromptText}>
                Create Prompt
              </Typography>
            </Button>
          </Box>

          <Box className={classes.tableContainer}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              paginationMode="client"
              disableRowSelectionOnClick
              autoHeight
              disableSelectionOnClick
              classes={{
                columnHeader: classes.tableHeaderCell,
              }}
            />
          </Box>
        </Box>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CustomLoader loading={isLoading} size={50} />
        </Backdrop>
        <ToastContainer />

        <PromptModal
          open={showModalOpen}
          handleClose={handleModalClose}
          promptData={selectedPrompt}
        />
        <Modal
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.Modal}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {isEdit ? "Edit Prompts:" : "Create Prompts:"}
            </Typography>
            <Box className={classes.TextInput}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Type</InputLabel>
                <Select
                  name="type"
                  value={createPrompt.type}
                  onChange={handleCreatePromptsChange}
                  label="Type"
                >
                  {Object.entries(PROMPT_TYPES).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                rows={15}
                sx={{ my: 2 }}
                placeholder="Prompts"
                label="Prompts"
                name="prompt"
                value={createPrompt.prompt}
                onChange={handleCreatePromptsChange}
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Status</InputLabel>
                <Select
                  value={createPrompt.is_active}
                  name="is_active"
                  onChange={handleCreatePromptsChange}
                  label={"Status"}
                >
                  {["Active", "Inactive"].map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {isEdit ? (
                <Button
                  variant="outlined"
                  className={classes.Btn}
                  onClick={handlePromptEditClick}
                >
                  Edit
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  className={classes.Btn}
                  onClick={handlePromptCreateClick}
                >
                  Create Prompts
                </Button>
              )}
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default Prompts;
