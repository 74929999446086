export const BASE_URL = "https://api.altlegal.metafic.co";

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const FILE_CATEGORY = {
  it: "Public tenders for IT services provision",
  distribution: "Public tenders for distribution and leasing services",
  general: "General tenders",
};

export const PROMPT_TYPES = {
  "Category Prompt": "category_prompt",
  "Summary Prompt": "summary_prompt",
  "Analysis Prompt": "analysis_prompt",
  "Comments Prompt": "comments_prompt",
  "Technical Comments Prompt": "technical_comments_prompt",
  "Metadata Prompt": "metadata_prompt",
  "IT Services and Provision Analysis Prompt": "c2_analysis_prompt",
  "IT Services and Provision Comments Prompt": "c2_comments_prompt",
  "IT Services and Provision Technical Comments Prompt":
    "c2_technical_comments_prompt",
  "Distribution and Leasing Analysis Prompt": "c3_analysis_prompt",
  "Distribution and Leasing Comments Prompt": "c3_comments_prompt",
  "Distribution and Leasing Technical Comments Prompt":
    "c3_technical_comments_prompt",
  "General Tender Comments Prompt": "c3_technical_comments_prompt",
};
