import React, { useEffect, useState } from "react";
import Header from "../Header";
import Sidebar from "./Sidebar";
import { Box, Typography, Button, Backdrop, IconButton } from "@mui/material";
import theme from "../../theme";
import { makeStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";
import { BASE_URL, FILE_CATEGORY } from "../../Constants/Constants";
import axios from "axios";
import { getToken } from "../../utils/utils";
import CustomLoader from "../../utils/CustomLoader";
import { toast, ToastContainer } from "react-toastify";
import {
  FILE_DOWNLOAD_SUCCESS_MESSAGE,
  FETCH_ERROR,
} from "../../utils/Validation";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((themes) => ({
  MainContainer: {
    display: "flex",
    width: "100%",
    background: "#F1F1F1",
  },
  ContentContainer: {
    width: "80%",
    padding: 20,
    height: "93vh",
    display: "flex",
    flexDirection: "column",
    background: "#fff",
  },
  Breadcrumbs: {
    display: "flex",
    gap: 4,
    alignItems: "center",
    marginBottom: "20px",
  },
  BreadcrumbsText: {
    color: theme.palette.secondary.black,
    fontFamily: "Helvetica",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "30px",
  },
  BreadcrumbsIcon: {
    width: "24px",
    height: "24px",
  },
  tableContainer: {
    width: "100%",
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: theme.palette.secondary.main,
    color: "#000",
  },
  BatchActionButton: {
    backgroundColor: "#F3F7F9 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "180px",
    height: "30px",
    padding: "7px 8.75px",
    gap: "10px",
    borderRadius: "3px 0px 0px 0px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    opacity: "0.65",
    marginBottom: "30px !important",
    textTransform: "none",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const Directory = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [fileData, setFileData] = useState([]);
  const token = getToken();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [c1Response, c2Response, c3Response] = await Promise.all([
          getC1Data(),
          getC2Data(),
          getC3Data(),
        ]);

        let combinedData = [
          ...c1Response.data,
          ...c2Response.data,
          ...c3Response.data,
        ];

        setFileData(combinedData);
      } catch (error) {
        toast.error("Error fetching data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const getC1Data = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/directory/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      response.data.forEach((item) => {
        item.category = FILE_CATEGORY.distribution;
      });
      return response;
    } catch (error) {
      console.log(error);
      return { data: [] }; // Return an empty array on error
    }
  };

  const getC2Data = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/directory/c2/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      response.data.forEach((item) => {
        item.category = FILE_CATEGORY.it;
      });
      return response; // Return the modified response
    } catch (error) {
      console.log(error);
      return { data: [] }; // Return an empty array on error
    }
  };

  const getC3Data = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/directory/c3/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      response.data.forEach((item) => {
        item.category = FILE_CATEGORY.general;
      });
      return response; // Return the modified response
    } catch (error) {
      console.log(error);
      return { data: [] }; // Return an empty array on error
    }
  };

  const classes = useStyles();
  const handleDownload = async (row) => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${BASE_URL}/document/download/${row.fileID}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );

      // Create a URL from the Blob
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));

      // Create a temporary link element
      const link = document.createElement("a");

      // Set the download attribute with a filename
      link.href = fileURL;
      link.setAttribute("download", `${row.name}`); // You can customize the file name here

      // Append the link to the body
      document.body.appendChild(link);

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up the URL object and remove the link
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(fileURL);
      toast.success(FILE_DOWNLOAD_SUCCESS_MESSAGE);
    } catch (err) {
      toast.error(FETCH_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = async (row) => {
    try {
      setIsLoading(true);
      const matchData = fileData.find((item) => item.document_id === row.id);

      navigate("/admin/dashboard", { state: { matchData } });
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  const rows = fileData.map((item, index) => {
    return {
      id: item.document_id,
      name: item.document_for_customer,
      date: item.document_date,

      category: item.category,
    };
  });

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerAlign: "center",
      headerClassName: "tableHeaderCell",
    },
    {
      field: "date",
      headerName: "Date",
      headerAlign: "center",
      headerClassName: "tableHeaderCell",
    },

    {
      field: "category",
      headerName: "Category",
      flex: 1,
      headerAlign: "center",
      headerClassName: "tableHeaderCell",
    },

    {
      field: "actions",
      headerName: "Actions",

      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <div>
          <Button
            variant="contained"
            sx={{ background: "#3E3E3D", display: "none" }}
            size="small"
            onClick={() => handleDownload(params.row)}
          >
            Download
          </Button>
          <IconButton
            color="#000"
            size="small"
            style={{ marginLeft: 8 }}
            onClick={() => handleEdit(params.row)}
          >
            <Edit />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <>
      <Header />
      <Box className={classes.MainContainer}>
        <Sidebar />
        <Box className={classes.ContentContainer}>
          <Box className={classes.Breadcrumbs}>
            <Typography className={classes.BreadcrumbsText}>
              Directory
            </Typography>
          </Box>

          <Box className={classes.tableContainer}>
            <DataGrid
              rows={rows}
              columns={columns}
              autoHeight
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              pagination
              paginationMode="client"
              disableSelectionOnClick
              classes={{
                columnHeader: classes.tableHeaderCell,
              }}
            />
          </Box>
        </Box>
      </Box>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CustomLoader loading={isLoading} size={50} />
      </Backdrop>
      <ToastContainer />
    </>
  );
};

export default Directory;
